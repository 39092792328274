import { Map } from '../../components/map/map'
import { useRef, useState } from 'react'
import { image } from '@brand/slots/pdp-map-pin/pdp-map-pin'
import { MapTypeToggle } from '../../components/map-toggle/map-toggle'

const DEFAULT_PDP_MAP_ZOOM = 12

type ListingLocationMapProps = {
  center: google.maps.LatLngLiteral
  zoom?: number
  showToggle?: boolean
  showControls?: boolean
}

export function ListingLocationMap({
  center,
  zoom,
  showToggle,
  showControls,
}: ListingLocationMapProps) {
  const mountRef = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<google.maps.Map>()
  const defaultMarker = useRef<google.maps.Marker | null>(null)

  if (map) {
    const marker = new window.google.maps.Marker({
      position: center,
      map,
      icon: {
        url: image.source,
        anchor: new google.maps.Point(23, 57),
        scaledSize: new google.maps.Size(image.width, image.height),
      },
    })
    marker.setMap(map)
    defaultMarker.current = marker
  }

  function handleReady(instance: google.maps.Map) {
    setMap(instance)
  }

  return (
    <>
      <Map
        onReady={handleReady}
        ref={mountRef}
        zoom={zoom || DEFAULT_PDP_MAP_ZOOM}
        zoomControl={showControls}
        zoomControlOptions={{
          position: 6,
        }}
        rotateControl={false}
        streetViewControl={false}
        mapTypeControl={false}
        fullscreenControl={false}
        center={center}
        gestureHandling="cooperative"
      />
      {showToggle && <MapTypeToggle map={map} />}
    </>
  )
}
