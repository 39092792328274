import styles from './location-match-badge.module.css'
import { ReactComponent as Location } from '@brand/icons/location.svg'
import { Truncate } from '../truncate/truncate'
import { Button } from '../button/button'
import { MapModal } from '../../features/map-modal/map-modal'
import type { ListingCard_ListingFragment } from '../../features/listing-card/__generated__/listing-card.gql'
import { startTransition, useCallback, useState } from 'react'
import clsx from 'clsx'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { yieldOrContinue } from 'main-thread-scheduling'

export type LocationMatchBadgeProps = {
  isMobile?: boolean
  locationToDisplay?: string | number | null
  listing: ListingCard_ListingFragment
}

export const LocationMatchBadge = ({
  locationToDisplay,
  isMobile,
  listing,
}: LocationMatchBadgeProps) => {
  const [mapModalOpen, setMapModalOpen] = useState(false)
  const openMapModal = useCallback(
    async function openModalMap() {
      startTransition(() => {
        setMapModalOpen(true)
      })
      await yieldOrContinue('idle')
      window.eventTracker?.track('click', {
        section: 'map-modal',
        item: 'open_map_modal',
      })
    },
    [setMapModalOpen]
  )

  const handleAfterClose = useCallback(async function handleAfterClose() {
    await yieldOrContinue('smooth')
    setMapModalOpen(false)
  }, [])

  const listingCardRedesignVersion = useFeatureVariable<number>(
    ['listing_card_redesign', 'version'],
    0
  )
  const listingCardRedesignVersionTwo = listingCardRedesignVersion === 2

  if (!locationToDisplay) {
    return null
  }

  if (isMobile) {
    return (
      <>
        <Button
          onClick={openMapModal}
          className={clsx(
            styles.mobileButton,
            listingCardRedesignVersionTwo && styles.withUpdatedStyles
          )}
          aria-label="open map modal for listing"
          data-tid="location-match-badge-btn"
          data-tag_item="location_match_badge"
        >
          <div className={styles.mobileButtonContent}>
            <Location className={styles.locationSvg} />
            <span className={styles.truncateWrapper}>{locationToDisplay}</span>
          </div>
        </Button>
        {mapModalOpen && (
          <MapModal
            listing={listing}
            zoom={14}
            showControls
            open={mapModalOpen}
            onAfterClose={handleAfterClose}
            heading={listing.name}
            subHeading={`${listing?.location?.city}, ${listing?.location?.stateAbbr} ${listing?.zipCode}`}
          />
        )}
      </>
    )
  }

  return (
    <div
      data-tid="location-match-badge"
      className={clsx(
        styles.locationMatchBadgeWrapper,
        listingCardRedesignVersionTwo && styles.withUpdatedStyles
      )}
    >
      <Location className={styles.locationSvg} />
      <Truncate as="span">{locationToDisplay}</Truncate>
    </div>
  )
}
