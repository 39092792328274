import type { HTMLAttributes, ReactNode } from 'react'

interface ListingCardDetailPageLinkProps
  extends HTMLAttributes<HTMLAnchorElement> {
  isMobile?: boolean
  href: string
  children: ReactNode
  onClick: () => void
}

export function ListingCardDetailPageLink(
  props: ListingCardDetailPageLinkProps
) {
  const { isMobile, href, children, onClick, ...rest } = props
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={href}
      onClick={onClick}
      data-tag_action="ignore" // tagging call fired in onClick
      {...rest}
      target={isMobile ? undefined : '_blank'}
    >
      {children}
    </a>
  )
}
