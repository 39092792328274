/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type UserActivityMutationVariables = Types.Exact<{
  input: Types.UserActivityInput;
}>;


export type UserActivityMutation = { userActivity: { success: boolean, message?: string | null } };


export const UserActivityDocument = `
    mutation UserActivity($input: UserActivityInput!) {
  userActivity(input: $input) {
    success
    message
  }
}
    `;

    export function createUserActivityFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchUserActivity(variables: UserActivityMutationVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<UserActivityMutation, UserActivityMutationVariables>(UserActivityDocument, variables, headers);
      }
    }

