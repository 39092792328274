import type { GalleryCarousel_ListingFragment } from '../detail/gallery/__generated__/gallery-carousel.gql'
import type { ListingCard_ListingFragment } from '../listing-card/__generated__/listing-card.gql'
import { dealsMap, DEFAULT_HOT_DEAL_LABEL } from '@brand/search/deals.const'

export function getDealsText(
  listing: ListingCard_ListingFragment | GalleryCarousel_ListingFragment
) {
  const firstDeal = listing.deals && listing.deals[0]

  if (firstDeal?.description) {
    return (
      dealsMap[firstDeal?.couponCategory] ??
      firstDeal?.couponCategory ??
      DEFAULT_HOT_DEAL_LABEL
    )
  }
  return undefined
}
