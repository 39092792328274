import { useForceUpdate, useHasMounted } from '@rentpath/react-hooks'
import type { ReactElement, ReactNode } from 'react'
import React, { useCallback, useEffect, useId, useState } from 'react'
import { createPortal } from 'react-dom'

const defaultProjection = {
  fromLatLngToDivPixel: () => ({ x: -9999, y: 0 }),
}

export type OverlayManagerChildProps = {
  map?: google.maps.Map
  projection?: google.maps.MapCanvasProjection | typeof defaultProjection
  lat: number
  lng: number
}

declare global {
  interface Window {
    __RP_SRP_MAP_OVERLAY__: google.maps.OverlayView
  }
}

export function OverlayManager({
  children,
  map,
}: {
  children: ReactNode
  map: google.maps.Map | undefined
}) {
  const id = useId()
  const containerId = `${id}-overlay-container`
  const hasMounted = useHasMounted()
  const forceUpdate = useForceUpdate()
  const [overlay, setOverlay] = useState<google.maps.OverlayView>()

  useEffect(() => {
    if (!hasMounted || overlay || !map) {
      return
    }

    class InternalOverlayView extends google.maps.OverlayView {
      private containerDiv: HTMLDivElement | null = null
      private readonly render: () => void

      constructor(render: () => void) {
        super()
        this.render = render
      }

      onAdd() {
        const panes = this.getPanes()
        this.containerDiv = document.createElement('div')
        this.containerDiv.setAttribute('id', containerId)
        google.maps.OverlayView.preventMapHitsFrom(this.containerDiv)
        panes?.floatPane.appendChild(this.containerDiv)
      }

      draw() {
        this.render()
      }

      onRemove() {
        this.containerDiv?.parentElement?.removeChild(this.containerDiv)
        this.containerDiv = null
      }
    }

    window.__RP_SRP_MAP_OVERLAY__ = new InternalOverlayView(forceUpdate)
    setOverlay(window.__RP_SRP_MAP_OVERLAY__)
  }, [hasMounted, forceUpdate, overlay, map, containerId])

  useEffect(() => {
    if (overlay && map && overlay.getMap() !== map) {
      overlay.setMap(map)

      return () => {
        overlay.setMap(null)
      }
    }
  }, [map, overlay])

  const getProjection = useCallback(() => {
    if (typeof window.__RP_SRP_MAP_OVERLAY__ !== 'undefined') {
      return window.__RP_SRP_MAP_OVERLAY__.getProjection()
    } else if (overlay) {
      return overlay.getProjection()
    }
    return defaultProjection
  }, [overlay])

  const overlayContainer = hasMounted && document.getElementById(containerId)

  if (!overlayContainer || !hasMounted) {
    return null
  }

  const projection = getProjection()

  const childrenWithProjection = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as ReactElement, { map, projection })
    }
  })

  return createPortal(childrenWithProjection, overlayContainer)
}
