import logo from '../static/images/app-banner-logo.png'

type OneLinkParamValue = string | number | boolean | undefined
export type OneLinkParams = Record<string, OneLinkParamValue>

export const ONE_LINK_URL = 'https://apartmentguide.onelink.me/hx28/4xpjhxie'
export const APP_STORE_URL = 'https://apps.apple.com/app/apartments/id292234839'
export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.primedia.apartmentguide&pli=1'

export const APP_NAME = 'ApartmentGuide.™'

export const RATING_COUNT = '60k'

export function getSmartOneLink(_args?: unknown) {
  return ONE_LINK_URL
}

export const appBannerData = {
  getSmartOneLink,
  logo,
  logoAltText: 'Apartmentguide.com. logo',
}
