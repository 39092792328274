import { memo } from 'react'
import { ListingCardCTA } from '@brand/slots/listing-card/listing-card-cta'
import type { ListingCardResultsCTAProps } from './listing-card.types'

export const ListingCardResultsCTA = memo(function ListingCardResultsCTA(
  props: ListingCardResultsCTAProps
) {
  const { listing, onClick, ctaClass } = props

  return (
    <ListingCardCTA
      ctaClass={ctaClass}
      isMobile={props.isMobile}
      listing={listing}
      currentRefinementSlugs={props.currentRefinementSlugs}
      // Optimization feature testing
      onCtaClick={onClick}
      locationRelativeToSearchedCity={props.locationRelativeToSearchedCity}
      isRentSrpRedesignEnabled={props.isRentSrpRedesignEnabled}
    />
  )
})
