/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type OneTapSignInMutationVariables = Types.Exact<{
  input: Types.UserLoginSocialInput;
}>;


export type OneTapSignInMutation = { userLoginSocial: { zutronId: string } };


export const OneTapSignInDocument = `
    mutation OneTapSignIn($input: UserLoginSocialInput!) {
  userLoginSocial(input: $input) {
    zutronId
  }
}
    `;

    export function createOneTapSignInFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchOneTapSignIn(variables: OneTapSignInMutationVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<OneTapSignInMutation, OneTapSignInMutationVariables>(OneTapSignInDocument, variables, headers);
      }
    }

