import { getEnv } from '../../config/get-env'

const env = getEnv()

let instance: OneTap | undefined

function noop(): void {
  // no-op
}

type OnTokenReceivedCallback = (
  token: google.accounts.id.CredentialResponse
) => void

export class OneTap {
  #booted = false

  #handleDisplayed: () => void

  #handleCanceled: () => void

  #handleAuthenticated: () => void

  #handleTokenReceived: OnTokenReceivedCallback

  constructor() {
    this.#handleDisplayed = noop
    this.#handleCanceled = noop
    this.#handleAuthenticated = noop
    this.#handleTokenReceived = noop
  }

  public static getInstance(): OneTap {
    if (instance === undefined) {
      instance = new OneTap()
    }

    return instance
  }

  public onDisplayed(cb: () => void): this {
    this.#handleDisplayed = cb
    return this
  }

  public onCanceled(cb: () => void): this {
    this.#handleCanceled = cb
    return this
  }

  public onAuthenticated(cb: () => void): this {
    this.#handleAuthenticated = cb
    return this
  }

  public onTokenReceived(cb: OnTokenReceivedCallback): this {
    this.#handleTokenReceived = cb
    return this
  }

  public boot(): void {
    // Guard against including the one-tap library multiple times
    // It can only ever be initialized once
    if (this.#booted) {
      return
    }

    const script = document.createElement('script')

    script.setAttribute('src', 'https://accounts.google.com/gsi/client')
    script.setAttribute('async', 'true')
    script.onload = () => {
      google.accounts.id.initialize({
        callback: this.#handleTokenReceived,
        client_id: env.NEXT_PUBLIC_GOOGLE_SOCIAL_CLIENT_ID,
        prompt_parent_id: 'one-tap-container',
        state_cookie_domain: 'rent.com/',
      })

      google.accounts.id.prompt(this.handlePrompt.bind(this))
    }

    document.body.appendChild(script)

    this.#booted = true
  }

  public cancel(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore | Google One Tap types not working w/ Yarn 2
    google.accounts.id.cancel()
  }

  private handlePrompt(
    notification: google.accounts.id.PromptMomentNotification
  ): void {
    switch (notification.getMomentType()) {
      case 'display': {
        if (notification.isDisplayed()) {
          this.#handleDisplayed()
        }
        break
      }
      case 'skipped': {
        if (
          notification.getSkippedReason() === 'user_cancel' ||
          notification.getSkippedReason() === 'tap_outside'
        ) {
          this.#handleCanceled()
        }
        break
      }
      case 'dismissed': {
        if (notification.getDismissedReason() === 'credential_returned') {
          this.#handleAuthenticated()
        }
        break
      }
      default: {
        break
      }
    }
  }
}
