'use client'

import { createContext, useContext, useState } from 'react'

import type { RequestData } from '../request-data/request-data.const'

type AppBannerContextProviderProps = {
  children: React.ReactNode
  requestData: Pick<RequestData, 'appBannerDismissedCookie' | 'isMobile'>
}

type AppBannerContextType = {
  isAppBannerShowing: boolean
  setIsAppBannerShowing: (isAppBannerShowing: boolean) => void
}

export const AppBannerContext = createContext<AppBannerContextType>({
  isAppBannerShowing: false,
  setIsAppBannerShowing: () => null,
})

export function AppBannerContextProvider({
  children,
  requestData,
}: AppBannerContextProviderProps) {
  const [isAppBannerShowing, setIsAppBannerShowing] = useState(
    Boolean(!requestData.appBannerDismissedCookie && requestData.isMobile)
  )

  return (
    <AppBannerContext.Provider
      value={{ isAppBannerShowing, setIsAppBannerShowing }}
    >
      {children}
    </AppBannerContext.Provider>
  )
}

export function useAppBanner() {
  return useContext(AppBannerContext)
}
