import clsx from 'clsx'
import { ReactComponent as CloseMatchIcon } from '@brand/icons/close-match.svg'
import { ReactComponent as CheckIcon } from '@brand/icons/check.svg'
import styles from './listing-card-soft-filters.module.css'
import { HorizontalScroll } from '../../components/horizontal-scroll/horizontal-scroll'
import type { SoftFiltersRelevance } from '../../__generated__/api-types'
import { useEffect, useRef, useState } from 'react'
import type { ReactNode } from 'react'
import { Anchor } from '../../components/anchor/anchor'
import { useIsTouchDevice } from '../../features/search/utils/use-is-touch-device'
import { getAmenityAlias } from '@brand/detail/amenities'

type ListingCardSoftFiltersProps = {
  filterRelevance?: SoftFiltersRelevance
  href: string
  onClick: () => void
  isMobile: boolean
  withUpdatedAgStyles?: boolean
  assumePerfectMatch?: boolean
}

type SoftFilterContainerProps = {
  children: ReactNode
  className?: string
}

function CloseMatchBadge() {
  return (
    <span className={styles.badgeContainer} data-tid="close-match-badge">
      <CloseMatchIcon className={styles.badgeIcon} />
      Close Match
    </span>
  )
}

export function ListingCardSoftFilters({
  filterRelevance,
  href,
  onClick,
  isMobile,
  withUpdatedAgStyles,
  assumePerfectMatch,
}: ListingCardSoftFiltersProps) {
  const listContainerRef = useRef<HTMLDivElement>(null)
  const [isOverfilled, setIsOverfilled] = useState(false)
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)
  const isTouchDevice = useIsTouchDevice()

  useEffect(() => {
    if (listContainerRef.current) {
      const currentlyOverFilled =
        listContainerRef.current.scrollWidth >
        listContainerRef.current.clientWidth
      if (currentlyOverFilled !== isOverfilled) {
        setIsOverfilled(currentlyOverFilled)
      }
    }
  }, [isOverfilled, listContainerRef])

  const matched = filterRelevance?.matched || []
  const unmatched = filterRelevance?.unmatched || []
  const combination = [...unmatched, ...matched].filter(Boolean)

  const createFilterList = () => {
    return combination.map((item, index) => {
      if (item) {
        const filterText = `${getAmenityAlias(item).toLowerCase()}${
          Boolean(index < combination.length - 1) ? ', ' : ''
        }`

        if (index < unmatched.length) {
          return (
            <span
              key={`filter-item-${item}`}
              className={clsx(styles.filterListItem, styles.unmatchedFilter)}
            >
              {filterText}
            </span>
          )
        } else {
          return (
            <span key={`filter-item-${item}`} className={styles.filterListItem}>
              {filterText}
            </span>
          )
        }
      }
    })
  }

  const handlePopoverOpen = () => setPopoverIsOpen(true)
  const handlePopoverClose = () => setPopoverIsOpen(false)

  const SoftFilterContainer = (props: SoftFilterContainerProps) =>
    isOverfilled ? (
      <div
        className={clsx(styles.softFilterContainer, {
          [styles.withUpdatedAgStyles]: withUpdatedAgStyles,
        })}
        {...props}
      />
    ) : (
      /* We need to preserve the referring search path for use on the PDP */
      /* eslint-disable-next-line react/jsx-no-target-blank */
      <a
        {...props}
        href={href}
        onClick={onClick}
        target={isMobile ? undefined : '_blank'}
        className={clsx(styles.softFilterContainer, {
          [styles.withUpdatedAgStyles]: withUpdatedAgStyles,
        })}
      />
    )

  const isPerfectMatch =
    filterRelevance?.unmatched?.length === 0 || assumePerfectMatch

  if (isPerfectMatch) {
    return (
      <SoftFilterContainer>
        <span className={styles.badgeContainer} data-tid="perfect-match-badge">
          <CheckIcon className={styles.badgeIcon} />
          Perfect Match
        </span>
      </SoftFilterContainer>
    )
  }

  return (
    <SoftFilterContainer>
      {isTouchDevice ? (
        <HorizontalScroll>
          <>
            <CloseMatchBadge />
            {withUpdatedAgStyles ? (
              <div className={styles.closeMatchBadgeDivider} />
            ) : null}
            {createFilterList()}
          </>
        </HorizontalScroll>
      ) : (
        <>
          <div ref={listContainerRef} className={styles.rowContainer}>
            {isOverfilled && (
              <div className={styles.button}>
                <div className={styles.buttonLinearGradient} />
                <div className={styles.anchorWrapper}>
                  <Anchor
                    as="button"
                    data-tid="close-match-more-button"
                    data-tag_item="x"
                    onFocus={handlePopoverOpen}
                    onBlur={handlePopoverClose}
                    onMouseOver={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    className={clsx({
                      [styles.moreWithUpdatedAgStyles]: withUpdatedAgStyles,
                    })}
                  >
                    {withUpdatedAgStyles ? '...more' : '...More'}
                  </Anchor>
                </div>
              </div>
            )}
            <CloseMatchBadge />
            {withUpdatedAgStyles ? (
              <div className={styles.closeMatchBadgeDivider} />
            ) : null}
            {createFilterList()}
          </div>

          {popoverIsOpen && (
            <div className={styles.panel}>
              <div className={styles.label}>Close Match</div>
              <div className={styles.panelFiltersList}>
                {createFilterList()}
              </div>
            </div>
          )}
        </>
      )}
    </SoftFilterContainer>
  )
}
