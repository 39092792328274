import type { PhotoFeedback } from '__generated__/api-types'
import { usePhotoOrderExperiment } from '../../features/ml-photo-order/use-photo-order-experiement'
import { setWinsAtom } from '../../features/search/search-results/atom/optimized-photos-feedback-atom'
import { useSetAtom } from 'jotai'
import { useCallback } from 'react'
import { yieldOrContinue } from 'main-thread-scheduling'

export function useSetWins(hasOptimizedPhotos: boolean) {
  const updateWins = useSetAtom(setWinsAtom)
  const { withOptimizedPhotos } = usePhotoOrderExperiment()

  const setWins = useCallback(
    async function setWins({ listingId, photoId }: PhotoFeedback) {
      if (!withOptimizedPhotos || !hasOptimizedPhotos) {
        return
      }

      await yieldOrContinue('idle')
      updateWins({
        listingId,
        photoId,
      })
    },
    [hasOptimizedPhotos, updateWins, withOptimizedPhotos]
  )

  return setWins
}
