'use client'

import clsx from 'clsx'
import styles from './home-page-seo-links.module.css'
import { useState } from 'react'
import { Anchor } from '../../../../../components/anchor/anchor'
import { ReactComponent as ChevronDownIcon } from '../../../icons/chevron-down.svg'
import { ReactComponent as ChevronUpIcon } from '../../../icons/chevron-up.svg'
import { IconButton } from '../../../../../components/icon-button/icon-button'

export type SectionData = {
  subtitle: string
  links: [string, string][]
}

export interface SeoLinksProps {
  section: SectionData
  column: number
  className?: string
}

export function HomePageSeoLinks({
  section,
  className,
  column,
}: SeoLinksProps) {
  const numberOfLinks = section.links.length
  const [isExpanded, setIsExpanded] = useState(false)
  const [showViewMore, setShowViewMore] = useState(numberOfLinks > 4)
  const middleColumn = column === 1
  const endColumn = column === 2

  if (
    !section ||
    !section.subtitle ||
    !Array.isArray(section.links) ||
    section.links.length === 0
  )
    return null

  function toggleExpanded() {
    setIsExpanded((prev) => !prev)
  }

  return (
    <div
      className={clsx(
        styles.container,
        middleColumn && styles.containerMiddle,
        endColumn && styles.containerEnd,
        className
      )}
    >
      <div
        className={clsx(
          styles.dropdown,
          middleColumn && styles.dropdownMiddle,
          endColumn && styles.dropdownEnd
        )}
      >
        <div className={styles.title}>
          <IconButton
            as={'button'}
            className={styles.button}
            rounded
            showLabel
            label={section.subtitle}
            iconPosition="right"
            icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            onClick={toggleExpanded}
          />
        </div>
        <ul className={clsx(styles.list, !isExpanded && styles.hidden)}>
          {section.links.map((link, index) => {
            const hideListItem = showViewMore && numberOfLinks > 4 && index > 3
            const href = link[0]
            const text = link[1]

            if (!href || !text) return null

            return (
              <li
                className={clsx(styles.listItem, hideListItem && styles.hidden)}
                key={text + href}
              >
                <Anchor className={styles.link} href={href}>
                  {text}
                </Anchor>
              </li>
            )
          })}
          {showViewMore && (
            <Anchor
              as="button"
              onClick={() => setShowViewMore(!showViewMore)}
              className={styles.link}
            >
              View More +
            </Anchor>
          )}
        </ul>
      </div>
    </div>
  )
}
