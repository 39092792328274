import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

export enum SelectionOriginEnum {
  MAP = 'MAP',
  LIST = 'LIST',
}
export type ListingRef = {
  position?: google.maps.LatLngLiteral
  id: string
  // differentiates between a map pin click (scrolls to search results card), and search results hover (highlights pin and marker popover active)
  selectionOrigin: SelectionOriginEnum
}

export const mapListingAtomBase = atom<ListingRef | null>(null)
export const activeListingAtom = atom(
  (get) => get(mapListingAtomBase),
  (_get, set, listingRef: ListingRef | null) => {
    set(mapListingAtomBase, listingRef)
  }
)

// read only
export const visitedListingsAtom = atomWithStorage<string[][]>(
  'visitedListings',
  new Array(14).fill([])
)

export const showPricesAtom = atom(false)

export const clusterMinDistanceAtom = atom(0)
