import type { RefObject, SyntheticEvent } from 'react'
import { useCallback } from 'react'
import { ReactComponent as ThreeDIcon } from '@brand/icons/three-d.svg'
import clsx from 'clsx'
import iconStyles from './icon-ctas.module.css'
import styles from './three-d-tour-button.module.css'
import type { TourFromHomeItem } from '../../features/detail/tour-from-home/get-tour-from-home-data'
import { Anchor } from '../anchor/anchor'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import type { TourFromHomeModalRef } from '../../features/detail/tour-from-home/tour-from-home-modal'
import { yieldOrContinue } from 'main-thread-scheduling'

interface ThreeDTourButtonProps {
  listingId: string
  tourInfo: TourFromHomeItem[]
  isAGListingCard?: boolean
  className?: string
  tourFromHomeModalRef?: RefObject<TourFromHomeModalRef>
}

export function ThreeDTourButton({
  listingId,
  tourInfo,
  isAGListingCard,
  className,
  tourFromHomeModalRef,
}: ThreeDTourButtonProps) {
  const isListingCardRedesignVersionTwo =
    useFeatureVariable<number>(['listing_card_redesign', 'version'], 0) === 2
  const isRentRedesignEnabled =
    useFeatureVariable<number>(['rent_srp_redesign', 'version'], 0) > 0

  const handleClick = useCallback(
    async function handleClick(e: SyntheticEvent<HTMLButtonElement>) {
      e.preventDefault()

      await yieldOrContinue('smooth')
      tourFromHomeModalRef?.current?.open({
        index: 0,
        data: { listingId, showOnly3dTours: true, data: tourInfo },
      })

      await yieldOrContinue('smooth')
      window.eventTracker?.track('click', {
        section: 'listing',
        item: '3d_tour',
        listing_id: listingId,
      })
    },
    [listingId, tourFromHomeModalRef, tourInfo]
  )

  const classNames = isListingCardRedesignVersionTwo
    ? styles.threeDTourButtonRedesignVersionTwo
    : isAGListingCard
    ? styles.threeDTourButtonRedesign
    : clsx(
        iconStyles.iconCtaStyle,
        styles.threeDTourButton,
        iconStyles.default,
        isRentRedesignEnabled && styles.withRentRedesignStyles
      )

  const Component = isAGListingCard ? Anchor : 'button'
  const threeDTourText = '3D Tour'

  return (
    <Component
      data-tag_action="ignore"
      className={clsx(classNames, className)}
      aria-label="3d-tour button"
      data-tid="3d-tour"
      onClick={tourFromHomeModalRef ? handleClick : undefined}
      as="button"
    >
      {isAGListingCard ? threeDTourText : <ThreeDIcon aria-hidden />}
    </Component>
  )
}
