import type { ComponentProps } from 'react'
import { CTAButtons } from '../../../../features/cta-buttons/cta-buttons'
import { CHECK_AVAILABILITY } from '../../../../features/cta-buttons/cta-buttons.const'
import type { StickyFooter_ListingFragment } from '../../../../features/detail/sticky-footer/__generated__/sticky-footer.gql'
import { getIsScheduleTourEnabled } from '../../../../features/tour-wizard/get-is-schedule-tour-enabled'
import styles from './footer-ctas.module.css'
import { useHideRequestTour } from '../../../../features/detail/use-hide-request-tour'
import { clsx } from 'clsx'
import { useRequestData } from '../../../../features/request-data/pages-router/use-request-data'
import { useMediaQuery } from '@rentpath/react-hooks'

type FooterCTAsProps = {
  listing: StickyFooter_ListingFragment
  className?: string
  primaryClassName?: string
  secondaryClassName?: string
  withStickyTabletFooterStyles?: boolean // For the handling of buttons on brand-color background
} & Pick<ComponentProps<typeof CTAButtons.EmailCTA>, 'onShowModal'>

export function FooterCTAs({
  listing,
  onShowModal,
  className,
  withStickyTabletFooterStyles,
  primaryClassName,
  secondaryClassName,
}: FooterCTAsProps) {
  const { isMobile } = useRequestData()
  const isTablet = useMediaQuery('(min-width: 768px)', !isMobile)
  const { tourProviderDetails } = listing || {}
  const isScheduleTourEnabled = getIsScheduleTourEnabled(tourProviderDetails)
  const showOutlinedButtons = isTablet && withStickyTabletFooterStyles
  const hideRequestTour = useHideRequestTour(
    listing.availabilityStatus,
    listing.tourProviderDetails,
    listing.requestTourOptOut
  )
  const isSmallScreen = isMobile && !isTablet

  if (hideRequestTour) {
    return (
      <CTAButtons
        className={clsx(className, styles.ctaButtons)}
        isMobile={isMobile}
        data-tid="sticky-footer-ctas"
        listing={listing}
      >
        <CTAButtons.PhoneCTA
          fluid={isSmallScreen}
          hideIcon={isSmallScreen}
          variant="outlined"
          className={secondaryClassName}
        />

        <CTAButtons.EmailCTA
          hideIcon={isSmallScreen}
          data-tid="cta-check-availability"
          fluid={isSmallScreen}
          variant={showOutlinedButtons ? 'outlined' : 'primary'}
          onShowModal={onShowModal}
          label={CHECK_AVAILABILITY}
          className={primaryClassName}
        />
      </CTAButtons>
    )
  }

  return (
    <CTAButtons
      className={clsx(className, styles.ctaButtons)}
      isMobile={isMobile}
      listing={listing}
    >
      <CTAButtons.EmailCTA
        data-tid="cta-check-availability"
        fluid={isSmallScreen}
        variant="outlined"
        onShowModal={onShowModal}
        label={CHECK_AVAILABILITY}
        hideIcon={isSmallScreen}
        className={secondaryClassName}
      />
      <CTAButtons.TourCTA
        data-tag_item={`${
          isScheduleTourEnabled ? 'schedule' : 'request'
        }_tour_button`}
        fluid={isSmallScreen}
        variant={showOutlinedButtons ? 'outlined' : 'primary'}
        data-tid={`cta-${
          isScheduleTourEnabled ? 'schedule-a-tour' : 'request-a-tour'
        }`}
        onShowModal={onShowModal}
        hideIcon={isSmallScreen}
        className={primaryClassName}
      />
    </CTAButtons>
  )
}
