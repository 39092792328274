import { useRouter } from 'next/router'
import type { ListingCardProps } from './listing-card.types'
import { useCallback, useMemo, useRef } from 'react'
import { getUnitAvailableText } from '../search/utils/get-listing-card-details'
import { TaggingPageType } from '../tagging/tagging.const'

export function useListingCardImpressionTracking(
  props: Pick<
    ListingCardProps,
    | 'isSRPCard'
    | 'listing'
    | 'updatePlays'
    | 'filterMatchResult'
    | 'listingCardIndex'
    | 'locationRelativeToSearchedCity'
  >
) {
  const {
    isSRPCard,
    listing,
    updatePlays,
    filterMatchResult,
    locationRelativeToSearchedCity,
  } = props
  const listingPhotos = listing.optimizedPhotos || listing.photos

  const listingCardIndex = (props.listingCardIndex || 0) + 1
  const route = useRouter()

  const handleFirstTimeInView = useCallback(() => {
    updatePlays({
      listingId: listing.id,
      photoId: listingPhotos?.[0]?.id || '',
    })
  }, [listing.id, updatePlays, listingPhotos])

  const photoIdx = useRef<number>(1)

  const listingUnitAvailableText = getUnitAvailableText({
    apiText: filterMatchResult?.unitsAvailableText || '',
    listingText: listing.unitsAvailableText || '',
    isBasic: listing.isBasic,
    availabilityStatus: listing.availabilityStatus,
  })

  const imageConversion = {
    eventCatergory: isSRPCard ? `page_${route.query.page ?? 1}` : '',
    eventLabel: `card_${listingCardIndex}_${listingUnitAvailableText
      .toLocaleLowerCase()
      .replaceAll(' ', '_')}`,
    listingPhotoId: listingPhotos ? listingPhotos[0]?.id : undefined,
  }

  const options = useMemo(
    () => ({
      listingIds: [listing.id],
      listingPhotoId: imageConversion.listingPhotoId,
      listingPhotoIndex: photoIdx.current,
      itemName: imageConversion.eventLabel,
      listingId: listing.id,
      listingLocationRelativeToSearchedCity: locationRelativeToSearchedCity,
      // Optimization feature testing
      handleFirstTimeInView,
    }),
    [
      handleFirstTimeInView,
      imageConversion.eventLabel,
      imageConversion.listingPhotoId,
      listing.id,
      locationRelativeToSearchedCity,
    ]
  )

  const taggingData = useMemo(
    () => ({
      page: TaggingPageType.SEARCH,
    }),
    []
  )

  return {
    options,
    taggingData,
    imageConversion,
  }
}
