import type { ReactNode } from 'react'
import React, { useCallback, useRef, useMemo, memo } from 'react'
import { OverlayManager } from './overlay-manager'
import styles from './map.module.css'

export interface MapViewProps extends google.maps.MapOptions {
  onReady?: (map: google.maps.Map) => void
  children?: ReactNode | ReactNode[]
}

const MemoizedOverlayManager = memo(OverlayManager)

export function MapView({ onReady, children, ...options }: MapViewProps) {
  const mapRef = useRef<google.maps.Map>()

  const mountRef = useCallback(
    (node: HTMLDivElement) => {
      if (node && !mapRef.current) {
        const instance = new window.google.maps.Map(node, options)
        mapRef.current = instance
        onReady?.(instance)
      }
    },
    [onReady, options]
  )

  const memoizedChildren = useMemo(() => children, [children])

  return (
    <div ref={mountRef} className={styles.mapElement}>
      <MemoizedOverlayManager map={mapRef.current}>
        {memoizedChildren}
      </MemoizedOverlayManager>
    </div>
  )
}
