/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type UserAddFavoriteListingMutationVariables = Types.Exact<{
  listingId: Types.Scalars['ID'];
  zutronId: Types.Scalars['ID'];
}>;


export type UserAddFavoriteListingMutation = { userAddSavedListing: { success: boolean, message?: string | null } };


export const UserAddFavoriteListingDocument = `
    mutation UserAddFavoriteListing($listingId: ID!, $zutronId: ID!) {
  userAddSavedListing(listingId: $listingId, zutronId: $zutronId) {
    success
    message
  }
}
    `;

    export function createUserAddFavoriteListingFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchUserAddFavoriteListing(variables: UserAddFavoriteListingMutationVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<UserAddFavoriteListingMutation, UserAddFavoriteListingMutationVariables>(UserAddFavoriteListingDocument, variables, headers);
      }
    }

