import { atom } from 'jotai'
import { atomWithReset } from 'jotai/utils'
import type {
  MutationOptimizePhotosFromFeedbackArgs,
  PhotoFeedback,
} from '__generated__/api-types'

export const winsAndPlaysAtom =
  atomWithReset<MutationOptimizePhotosFromFeedbackArgs>({
    plays: [],
    wins: [],
  })

export const winsPlaysHistoryAtom =
  atom<MutationOptimizePhotosFromFeedbackArgs>({
    plays: [],
    wins: [],
  })

export const setWinsPlaysHistoryAtom = atom<
  null,
  [MutationOptimizePhotosFromFeedbackArgs],
  void
>(
  null,
  (get, set, newWinsPlaysRecord: MutationOptimizePhotosFromFeedbackArgs) => {
    const prev = get(winsPlaysHistoryAtom)
    const winsState = [...prev.wins, ...newWinsPlaysRecord.wins]
    const playsState = [...prev.plays, ...newWinsPlaysRecord.plays]
    set(winsPlaysHistoryAtom, { wins: winsState, plays: playsState })
  }
)

export const setWinsAtom = atom<null, [PhotoFeedback], void>(
  null,
  (get, set, win: PhotoFeedback) => {
    const prev = get(winsAndPlaysAtom)
    const history = get(winsPlaysHistoryAtom)

    const doesWinExist = [...history.wins, ...prev.wins].find(
      (item) => item.listingId === win.listingId
    )
    const doesPlayExist = [...history.plays, ...prev.plays].find(
      (item) => item.listingId === win.listingId
    )

    if (doesWinExist && doesPlayExist) return
    const winsState = doesWinExist ? prev.wins : [...prev.wins, win]
    const playsState = doesPlayExist ? prev.plays : [...prev.plays, win]
    set(winsAndPlaysAtom, { wins: winsState, plays: playsState })
  }
)

export const setPlaysAtom = atom<null, [PhotoFeedback], void>(
  null,
  (get, set, play: PhotoFeedback) => {
    const prev = get(winsAndPlaysAtom)
    const history = get(winsPlaysHistoryAtom)
    if (
      [...prev.plays, ...history.plays].find(
        (item) => item.listingId === play.listingId
      )
    )
      return
    const playsState = [...prev.plays, play]
    set(winsAndPlaysAtom, { ...prev, plays: playsState })
  }
)
