interface assignOrOpenPathParams {
  path: string
  isPropertyPath: boolean
  isMobile?: boolean
}

export function assignOrOpenPath({
  path,
  isPropertyPath,
  isMobile,
}: assignOrOpenPathParams): void {
  if (isPropertyPath) {
    if (isMobile) {
      window.location.assign(path)
    } else {
      window.open(path)
    }
  } else {
    window.location.assign(path)
  }
}
