interface DealsMap {
  [key: string]: string
}

export const dealsMap: DealsMap = {
  'Special Offer': 'Exclusive Deal',
  'Cash Off': 'Discounted Rate',
  'New Lower Price': 'Price Cut',
  'Waived Fees': 'Reduced Fees',
  'Weeks Free': 'Free Weeks',
}

export const DEFAULT_HOT_DEAL_LABEL = 'Exclusive Deal'
