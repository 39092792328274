/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type UserRemoveFavoriteListingMutationVariables = Types.Exact<{
  listingId: Types.Scalars['ID'];
  zutronId: Types.Scalars['ID'];
}>;


export type UserRemoveFavoriteListingMutation = { userRemoveSavedListing: { success: boolean, message?: string | null } };


export const UserRemoveFavoriteListingDocument = `
    mutation UserRemoveFavoriteListing($listingId: ID!, $zutronId: ID!) {
  userRemoveSavedListing(listingId: $listingId, zutronId: $zutronId) {
    success
    message
  }
}
    `;

    export function createUserRemoveFavoriteListingFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchUserRemoveFavoriteListing(variables: UserRemoveFavoriteListingMutationVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<UserRemoveFavoriteListingMutation, UserRemoveFavoriteListingMutationVariables>(UserRemoveFavoriteListingDocument, variables, headers);
      }
    }

