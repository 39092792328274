import { ReactComponent as HeartFilledIcon } from '@brand/icons/heart-filled.svg'
import { ReactComponent as HeartIcon } from '@brand/icons/heart.svg'
import { ReactComponent as BookmarkIcon } from '@brand/icons/bookmark-empty.svg'
import { ReactComponent as BookmarkFilledIcon } from '@brand/icons/bookmark-filled.svg'
import clsx from 'clsx'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useCallback, useRef, useState } from 'react'
import { graphqlRequesterOptions } from '../../config/graphql-requester-options'
import {
  emailCaptureFavoriteCountAtom,
  emailPromptShownAtom,
} from '../../features/email-capture/email-capture.store'
import { ModalName } from '../../features/modals/modals.config'
import { useOpenModal } from '../../features/modals/use-open-modal'
import {
  lastSavedListingIdAtom,
  useIsLoggedIn,
  userSavedListingIds,
  useZutronId,
} from '../../features/user/user.store'
import { createUserAddFavoriteListingFetcher } from './__generated__/add-favorite.gql'
import { createUserRemoveFavoriteListingFetcher } from './__generated__/remove-favorite.gql'
import styles from './icon-ctas.module.css'
import { useSubmitUserActivityHandler } from '../../features/user-event-tracking/use-submit-user-activity-handler'
import { Button } from '../button/button'
import brandStyles from '@brand/ctas/icon-ctas.module.css'
import { useFeatureVariable } from '@rentpath/ab-testing-react'

type FavoriteIconProps = {
  isFavorite: boolean
  isRedesign: boolean
  hasBecomeFavored: boolean
}

function FavoriteIcon({
  isFavorite,
  isRedesign,
  hasBecomeFavored,
}: FavoriteIconProps) {
  const isBookmarkIconEnabled = useFeatureVariable(
    ['bookmark_icon', 'bookmarkIconEnabled'],
    false
  )

  const FilledIcon = isBookmarkIconEnabled
    ? BookmarkFilledIcon
    : HeartFilledIcon
  const EmptyIcon = isBookmarkIconEnabled ? BookmarkIcon : HeartIcon

  return isFavorite ? (
    <FilledIcon
      aria-hidden
      className={clsx({
        [styles.bookmarkIcon]: isBookmarkIconEnabled,
        [styles.clickAnimation]: hasBecomeFavored && isRedesign,
        [styles.filledHeart]: isRedesign,
      })}
    />
  ) : (
    <EmptyIcon
      className={clsx({
        [styles.bookmarkIcon]: isBookmarkIconEnabled,
      })}
      aria-hidden
    />
  )
}

interface FavoriteButtonProps {
  listingId: string
  onChange?: (isAdding: boolean) => void | Promise<void>
  onShowModal?: () => void
  listingCardRedesignVersion?: number
  className?: string
  isMarkerPopUp?: boolean
  isRentSrpRedesign?: boolean
  isAGListingCard?: boolean
}

const FAVORITE_THRESHOLD = 1

const addFavorite = createUserAddFavoriteListingFetcher(graphqlRequesterOptions)
const removeFavorite = createUserRemoveFavoriteListingFetcher(
  graphqlRequesterOptions
)

export function FavoriteButton({
  listingId,
  onChange,
  onShowModal,
  listingCardRedesignVersion,
  className,
  isMarkerPopUp,
  isRentSrpRedesign,
  isAGListingCard,
}: FavoriteButtonProps) {
  const [loading, setLoading] = useState(false)
  const hasBecomeFavored = useRef(false)
  const [favorites, setFavorites] = useAtom(userSavedListingIds)
  const isFavorite = favorites.includes(listingId)
  const saveText = isFavorite ? 'unsave' : 'save'
  const zutronId = useZutronId()
  const submitUserActivityFavorite = useSubmitUserActivityHandler({
    listingId,
    type: 'favorited',
  })
  const showFullRentRedesignButton = isRentSrpRedesign && !isMarkerPopUp
  const setLastSavedListingId = useSetAtom(lastSavedListingIdAtom)
  const openModal = useOpenModal()
  const setEmailCaptureFavoriteCount = useSetAtom(emailCaptureFavoriteCountAtom)
  const hasEmailPromptBeenShown = useAtomValue(emailPromptShownAtom)
  const userIsLoggedIn = useIsLoggedIn()

  // Keep track in a mutable ref - I don't want updating this value to re-render the component
  const favoriteClicks = useRef<number>(0)

  const isBookmarkIconEnabled = useFeatureVariable(
    ['bookmark_icon', 'bookmarkIconEnabled'],
    false
  )
  const isBookmarkLabelEnabled = useFeatureVariable(
    ['bookmark_icon', 'bookmarkLabelEnabled'],
    false
  )

  // if the user favorites more than one item on the page,
  // show the email-capture form and store the listing id.
  const handleFavoriteToggle = useCallback(async () => {
    if (listingId && zutronId) {
      setLoading(true)
      if (isFavorite) {
        removeFavorite({
          listingId,
          zutronId: zutronId,
        }).tapOk(() => {
          setFavorites(
            favorites.filter((existingId) => existingId !== listingId)
          )
        })
      } else {
        hasBecomeFavored.current = true
        addFavorite({ listingId, zutronId: zutronId }).tapOk(() => {
          void submitUserActivityFavorite()
          setLastSavedListingId(listingId)

          favoriteClicks.current++

          // When we pass the configured limit, open the modal
          if (
            favoriteClicks.current >= FAVORITE_THRESHOLD &&
            !hasEmailPromptBeenShown &&
            !userIsLoggedIn
          ) {
            openModal({
              id: ModalName.EMAIL_CAPTURE,
              props: {
                listingId: listingId,
                modalSource: 'modal_impression',
              },
            })
            setEmailCaptureFavoriteCount(favoriteClicks.current)
            onShowModal?.()
          }

          setFavorites(favorites.concat([listingId]))
        })
      }
      await onChange?.(!isFavorite)
      setLoading(false)
    }
  }, [
    zutronId,
    favorites,
    hasEmailPromptBeenShown,
    isFavorite,
    listingId,
    onChange,
    onShowModal,
    openModal,
    setEmailCaptureFavoriteCount,
    setFavorites,
    setLastSavedListingId,
    submitUserActivityFavorite,
    userIsLoggedIn,
  ])

  const buttonStateCls = isFavorite ? styles.filled : styles.default

  const isRedesignV2 = listingCardRedesignVersion === 2
  const componentClass = isRentSrpRedesign
    ? styles.withRentRedesignStyles
    : isRedesignV2
    ? styles.favoriteButtonRedesignV2
    : clsx(styles.iconCtaStyle, buttonStateCls, {
        [styles.favoriteButtonAG]: isAGListingCard,
      })

  const Component =
    isRedesignV2 || showFullRentRedesignButton ? Button : 'button'
  const bookmarkSaveText = isFavorite ? 'Saved' : 'Save'

  return (
    <Component
      disabled={loading}
      data-tag_item="favorite_properties"
      data-tag_selection={saveText}
      data-tid={`Favorite-${isFavorite ? 'on' : 'off'}`}
      className={clsx(componentClass, className, styles.favoriteButton, {
        [styles.bookmarkButton]: isBookmarkIconEnabled,
        [styles.bookmarkLabelButton]:
          isBookmarkIconEnabled &&
          isBookmarkLabelEnabled &&
          listingCardRedesignVersion !== 1,
        [styles.bookmarkLabelButtonRedesignV1]:
          isBookmarkIconEnabled &&
          isBookmarkLabelEnabled &&
          listingCardRedesignVersion === 1,
        [brandStyles.bookmarkLabelCta]:
          isBookmarkLabelEnabled && isBookmarkIconEnabled,
      })}
      aria-label={`${saveText} favorite`}
      onClick={handleFavoriteToggle}
      variant={
        isRedesignV2
          ? 'agTertiaryRedesign'
          : showFullRentRedesignButton
          ? 'rentTertiaryRedesign'
          : undefined
      }
      rounded={isRedesignV2 ? true : undefined}
    >
      {showFullRentRedesignButton && (
        <span className={styles.savedText}>Saved</span>
      )}
      <FavoriteIcon
        isFavorite={isFavorite}
        isRedesign={isRedesignV2 || Boolean(isRentSrpRedesign)}
        hasBecomeFavored={hasBecomeFavored.current}
      />
      {isBookmarkIconEnabled && isBookmarkLabelEnabled ? (
        <span className={styles.bookmarkLabel}>{bookmarkSaveText}</span>
      ) : null}
    </Component>
  )
}
