import { useEffect, useState } from 'react'

export function useIsTouchDevice() {
  const [isTouchDevice, setIsTouchDevice] = useState(false)
  useEffect(() => {
    if (!navigator) return

    const testCurrentDevice = Boolean(navigator?.maxTouchPoints)

    if (testCurrentDevice !== isTouchDevice) {
      setIsTouchDevice(true)
    }
  }, [isTouchDevice])

  return isTouchDevice
}
