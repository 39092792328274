import type { TourFromHome_ListingFragment } from './__generated__/tour-from-home.gql'

export type TourFromHomeItem = {
  caption: string
  isUnpaid: boolean
  publicId: string
  tourUrl: string
  vendorThumbnailUrl?: string
  type: 'video' | '3d'
}

function setAutoplayHdUrl(tourUrl: string) {
  if (tourUrl.toLowerCase().includes('matterport.com')) {
    try {
      const url = new URL(tourUrl)
      url.searchParams.set('play', '1')
      return url.href
    } catch {}
  }

  return tourUrl
}

function setAutoplayVideoUrl(tourUrl: string) {
  try {
    const url = new URL(tourUrl)
    url.searchParams.set('autoplay', '1')
    url.searchParams.set('autopause', '0')
    return url.href
  } catch {}

  return tourUrl
}

function makePhotoIterator(
  startIndex: number,
  listing: TourFromHome_ListingFragment
) {
  const photos = listing?.photos?.filter((photo): photo is { id: string } =>
    Boolean(photo?.id)
  )
  let index = (startIndex || 0) - 1

  if (typeof photos?.length !== 'number' || photos.length < 1) {
    return null
  }

  return () => {
    index += 1

    if (index >= photos.length) {
      index = photos.length - 1
    }

    return photos[index].id
  }
}

export function getHdTours(
  listing: TourFromHome_ListingFragment
): TourFromHomeItem[] {
  if (!listing?.hdTours?.length) {
    return []
  }

  const publicId = listing.photos?.[0]?.id

  if (!publicId) {
    return []
  }

  return listing.hdTours
    .filter((hdTour) => Boolean(hdTour?.url))
    .map((hdTour) => ({
      caption: hdTour.name || '',
      isUnpaid: listing.isUnpaid,
      publicId: hdTour.imageToken || publicId,
      tourUrl: hdTour.url ? setAutoplayHdUrl(hdTour.url) : '',
      vendorThumbnailUrl: hdTour?.vendorThumbnailUrl || '',
      type: '3d',
    }))
}

export function getVideoTours(
  listing: TourFromHome_ListingFragment
): TourFromHomeItem[] {
  const hasHdTour = listing?.hdTours?.[0]
  const getPhoto = makePhotoIterator(hasHdTour ? 2 : 1, listing)

  if (!listing?.videos?.length || !getPhoto) {
    return []
  }

  return listing.videos
    .filter((video) => Boolean(video?.videoUrl))
    .map((video) => ({
      caption: video.title || '',
      isUnpaid: listing.isUnpaid,
      publicId: video.imageToken || getPhoto(),
      tourUrl: video.videoUrl ? setAutoplayVideoUrl(video.videoUrl) : '',
      type: 'video',
    }))
}

export function getTourFromHomeData(listing: TourFromHome_ListingFragment) {
  return [...getHdTours(listing), ...getVideoTours(listing)]
}
