import { getCurrencyText, numberWithCommas } from '@rentpath/web-utils'
import type { FilterMatchResults_Fragment } from '../../search/search-results/__generated__/search-results.gql'
import { getBedText } from '../../search/utils/get-bed-text'

const sortNumber = (a: number, b: number) => {
  return a - b
}

const getNumbersArray = (value: Array<number | null | undefined>) =>
  Array.isArray(value)
    ? (value?.filter((v) => typeof v === 'number') as number[])
    : []

export const getSortedNumbersArray = (
  value: Array<number | null | undefined>
) => (Array.isArray(value) ? getNumbersArray(value).sort(sortNumber) : [])

type GetFilterMatchResultPriceSqFtDisplayValuesArgs = {
  bedCountData?: FilterMatchResults_Fragment['bedCountData']
  withAbbreviatedText?: boolean
}

type BedCountData = {
  sqFtText?: string
  priceText?: string
  bedText?: string
}

// For display purposes, combine all objects starting with the third one into a single object
// Use the aggregate min square feet value and min price value across all objects being combined (e.g., 2 Beds, 3 Beds, 4 Beds)
export function getFilterMatchResultPriceSqFtDisplayValues({
  bedCountData,
  withAbbreviatedText,
}: GetFilterMatchResultPriceSqFtDisplayValuesArgs) {
  if (!bedCountData?.length) {
    return []
  }

  return (
    bedCountData.reduce<BedCountData[]>((acc, val, index) => {
      if (index > 2 || typeof val?.beds !== 'number') {
        return acc
      }

      let hasPlusOnBedCount = false
      const { low: sqFtLow, high: sqFtHigh } = val?.squareFeet || {}
      let sqFeet = [sqFtLow, sqFtHigh]
      const { low: priceLow, high: priceHigh } = val?.prices || {}
      let prices = [priceLow, priceHigh]

      if (bedCountData && bedCountData?.length > 3 && index === 2) {
        hasPlusOnBedCount = true
        bedCountData.slice(3).forEach((obj) => {
          sqFeet = [...sqFeet, obj?.squareFeet?.low, obj?.squareFeet?.high]
          prices = [...prices, obj?.prices?.low, obj?.prices?.high]
        })
      }

      const orderedSqFeet = getSortedNumbersArray(sqFeet)
      const orderedPrices = getSortedNumbersArray(prices)
      let sqFtText: undefined | string = undefined
      let priceText: undefined | string = undefined

      if (orderedSqFeet?.length) {
        const smallestSqFeet = orderedSqFeet[0]
        const sqFtMinText = numberWithCommas(smallestSqFeet)

        const hasMultiSqFtValues =
          smallestSqFeet !== orderedSqFeet[orderedSqFeet.length - 1]
        sqFtText = `${sqFtMinText}${hasMultiSqFtValues ? '+ Sqft' : ' Sqft'}`
      }

      if (orderedPrices?.length) {
        const lowestPrice = orderedPrices[0]
        const minPriceText = getCurrencyText(lowestPrice)
        const hasMultiplePriceValues =
          lowestPrice !== prices[orderedPrices.length - 1]
        priceText = `${minPriceText}${hasMultiplePriceValues ? '+' : ''}`
      }

      return [
        ...acc,
        {
          sqFtText,
          priceText,
          bedText: getBedText(
            Number(bedCountData[index]?.beds),
            hasPlusOnBedCount,
            withAbbreviatedText
          ),
        },
      ]
    }, []) || []
  ).filter((detail) => Boolean(detail.priceText || detail.sqFtText))
}
