import clsx from 'clsx'
import styles from './rating-stars.module.css'

export function RatingStars({
  percent = 100,
  className,
}: {
  percent?: number
  className?: string
}) {
  return (
    <span className={clsx(styles.starBackDrop, className)} data-tid="rating">
      <span
        className={styles.stars}
        data-tid="rating-stars"
        data-tag_item="stars"
        style={{ width: `${percent}%` }}
      />
    </span>
  )
}
