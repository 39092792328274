import { LocationSearchTypes } from '../../../__generated__/api-types'
import { type LocationBadgeRequirementsReturn } from '../../../components/location-match-badge/get-location-badge-metadata'
import { type ListingCard_ListingFragment } from '../../../features/listing-card/__generated__/listing-card.gql'

export const getLocationBadgeText = ({
  locationBadgeRequirements,
  listing,
}: {
  locationBadgeRequirements?: LocationBadgeRequirementsReturn
  listing: ListingCard_ListingFragment
}) => {
  const { location, zipCode, hoodIds } = listing
  const { shapeId } = locationBadgeRequirements || {}

  if (!locationBadgeRequirements || !location) {
    return
  }

  const cityState = `${location.city}, ${location.stateAbbr}`

  if (
    locationBadgeRequirements.locationSearchType === LocationSearchTypes.Zip &&
    zipCode === shapeId
  ) {
    return zipCode
  }

  if (
    shapeId &&
    locationBadgeRequirements.locationSearchType === LocationSearchTypes.Hood &&
    hoodIds?.includes(Number(shapeId))
  ) {
    return locationBadgeRequirements?.neighborhoodSearched
  }

  return cityState
}
