import React, { forwardRef } from 'react'
import { Wrapper } from '@googlemaps/react-wrapper'
import { GOOGLE_MAPS_API_KEY } from './map.const'

import styles from './map.module.css'
import { MapView } from './map-view'

import type { MapViewProps } from './map-view'

export const Map = forwardRef<HTMLDivElement, MapViewProps>(function Map(
  { children, ...options },
  ref
) {
  return (
    <div style={{ display: 'flex', height: '100%', width: '100%' }} ref={ref}>
      <Wrapper
        apiKey={GOOGLE_MAPS_API_KEY}
        render={() => <div className={styles.mapSkeleton} />}
      >
        <MapView gestureHandling="greedy" {...options}>
          {children}
        </MapView>
      </Wrapper>
    </div>
  )
})
