import clsx from 'clsx'
import { memo } from 'react'
import { FooterCTAs } from '@brand/slots/footer-ctas/footer-ctas'
import styles from './sticky-footer.module.css'

import { FavoriteButton } from '../../../components/icon-ctas/favorite-button'

import type { StickyFooter_ListingFragment } from './__generated__/sticky-footer.gql'

type StickyFooterProps = {
  isVisible: boolean
  listing: StickyFooter_ListingFragment
  onShowModal?: () => void
}

export const StickyFooter = memo(function StickyFooter({
  isVisible,
  listing,
  onShowModal,
}: StickyFooterProps) {
  if (listing.isBasic) return null

  return (
    <div
      className={clsx(
        styles.stickyFooter,
        !isVisible && styles.stickyFooterHidden
      )}
      data-tag_section="sticky_footer"
      data-tid="sticky-footer"
    >
      <div className={styles.favoriteButtonWrapper}>
        <FavoriteButton onShowModal={onShowModal} listingId={listing.id} />
      </div>
      <div className={styles.propertyName}>{listing.name}</div>
      <FooterCTAs
        data-tid="sticky-footer-ctas"
        className={styles.footerCtas}
        onShowModal={onShowModal}
        listing={listing}
        withStickyTabletFooterStyles
      />
    </div>
  )
})
