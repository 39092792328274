import { submitUserActivity } from './submit-user-activity'
import { useUser } from '../user/user.store'
import { getLocalStorage } from '@rentpath/web-utils'
import type { UserActivityInput } from '__generated__/api-types'
import { useCallback } from 'react'
import { isValidEmail } from '@rentpath/form-validation'
import { leadCookieAtom } from '../../features/lead-form/lead-cookie.store'
import { useAtomValue } from 'jotai'
import { yieldOrContinue } from 'main-thread-scheduling'

function truncateName(name: string | null | undefined) {
  if (!name) {
    return null
  }

  return name.substring(0, 50)
}
export interface SubmitUserActivityHandlerProps {
  listingId: string
  type: string
}

export function useSubmitUserActivityHandler(
  props: SubmitUserActivityHandlerProps
) {
  const user = useUser()
  const leadCookie = useAtomValue(leadCookieAtom)

  const setUserActivity = useCallback(async () => {
    await yieldOrContinue('idle')

    const localStorageEmail = getLocalStorage('user_email')
    if (user?.id || leadCookie.email || localStorageEmail) {
      const email = user?.email || leadCookie.email || localStorageEmail
      const [leadCookieFirstName, ...restOfNames] = leadCookie.name.split(' ')

      const leadCookieLastName = restOfNames.join(' ')

      const firstName = truncateName(user?.givenName || leadCookieFirstName)
      const lastName = truncateName(user?.familyName || leadCookieLastName)

      // this hook may fire multiple times as the page loads and a zid fetched
      // with that in mind, we don't want to fire the mutation until we have a zid to avoid dupes.
      if (user?.zutronId && email && isValidEmail(email)) {
        const input: UserActivityInput = {
          email,
          firstName,
          lastName,
          listingId: props.listingId,
          registered: !!user?.id,
          type: props.type,
          zutronId: user?.zutronId,
        }

        await yieldOrContinue('idle')
        submitUserActivity({
          input,
        })
      }
    }
  }, [
    leadCookie.email,
    leadCookie.name,
    props.listingId,
    props.type,
    user?.email,
    user?.familyName,
    user?.givenName,
    user?.id,
    user?.zutronId,
  ])

  return setUserActivity
}
