import { useEffect, useState } from 'react'
import { ReactComponent as GlobeIcon } from '@brand/icons/globe.svg'
import { ReactComponent as MapIcon } from '@brand/icons/map.svg'
import styles from './map-toggle.module.css'

type MapType = 'roadmap' | 'hybrid'

export const ROADMAP_VIEW_LABEL = 'Show street view'
export const HYBRID_VIEW_LABEL = 'Show hybrid view'

interface MapTypeToggleProps {
  map?: google.maps.Map
}

export function MapTypeToggle({ map }: MapTypeToggleProps) {
  const [type, setType] = useState<MapType>('roadmap')
  const label = type === 'hybrid' ? ROADMAP_VIEW_LABEL : HYBRID_VIEW_LABEL

  const handleClick = () => {
    setType((prev) => (prev === 'hybrid' ? 'roadmap' : 'hybrid'))
  }

  useEffect(() => {
    if (type && map) {
      map.setMapTypeId(type)
    }
  }, [type, map])

  const MapTypeIcon = type === 'roadmap' ? GlobeIcon : MapIcon

  return (
    <button
      type="button"
      className={styles.mapToggle}
      aria-label={label}
      onClick={handleClick}
      id="icon-toggle"
    >
      <div className={styles.icon}>
        <MapTypeIcon aria-hidden="true" />
      </div>
    </button>
  )
}
