import { RatingStars } from './rating-stars'
import styles from './rating.module.css'

export const RATING_STAR_COUNT = 5

export type RatingProps = {
  /** Total number of ratings used for display. */
  numRatings?: number

  /** Percent (0-100) */
  percent: number
}

/**
 * Displays ratings in the classic star format.
 */
export function Rating(props: RatingProps) {
  const percent = props.percent === undefined ? 0 : props.percent
  // Multiply by two and divide by two to round number to the nearest half star
  const starFraction = Math.round((percent / 100) * RATING_STAR_COUNT * 2) / 2

  return (
    <div className={styles.container}>
      <div className="sr-only">
        {`${starFraction}/${RATING_STAR_COUNT} stars based on ${props.numRatings} reviews`}
      </div>

      <RatingStars percent={percent} />

      {typeof props.numRatings === 'number' && props.numRatings > 0 && (
        <span className={styles.ratingText} data-tid="rating-number">
          {props.numRatings}
        </span>
      )}
    </div>
  )
}
