import { pluralize } from '@rentpath/web-utils'

export const getBedText = (
  bedCount: number,
  withPlus?: boolean,
  withAbbreviatedText?: boolean
) => {
  const spacing = withAbbreviatedText ? '' : ' '
  return bedCount === 0
    ? 'Studio'
    : `${bedCount}${withPlus ? '+' : ''}${spacing}${
        withPlus && !withAbbreviatedText
          ? 'Beds'
          : withAbbreviatedText
          ? 'bd'
          : pluralize(bedCount, 'Bed')
      }`
}
